import { Cloudinary } from 'cloudinary-core';

// https://cloudinary.com/documentation/responsive_images#automating_responsive_images_with_client_hints

// NOTE: (TomLuce) Seems to be an issue with Cloudinary Core where, even though we've got a babel
// polyfill, the JS still bugs out. This is meant to sort it...
// https://stackoverflow.com/questions/53331180/babel-polyfill-being-included-but-foreach-still-doesnt-work-in-ie11-on-nodelis
// Polyfill for missing forEach on NodeList for IE11
if ((window as any).NodeList && !NodeList.prototype.forEach) {
  (NodeList.prototype as any).forEach = Array.prototype.forEach;
}

document.addEventListener('turbolinks:load', () => {
  const cl = Cloudinary.new({ cloud_name: 'Infuse Group' });
  cl.responsive();
});
